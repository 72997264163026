.center {
  text-align: center;
}

.button-row {
  margin-top: 20px;
}

.admin-button {
  width: 200px;
}

.raptor-admin-dropdown {
  text-align: right;
  margin-left: 30px;
  margin-bottom: 20px;
}

.raptor-admin-view {
  padding-left: 30px;
  padding-right: 20px;
}

.app-fill-raptor-admin {
  padding-top: 50px;
  height: 100%;
  min-height: 100%;
}

.home-link {
  background-color: rgb(21, 108, 223);
  color: white;
}

.dropdown-menu.show {
  top: unset !important;
  transform: unset !important;
  max-height: 600px !important;
}

.order-modal {
  width: 1100px;
  max-width: none !important;
}
.order-edit-row {
  padding: 10px 25px;
  display: flex;
}
.order-edit-column {
  padding: 10px 25px;
}
.order-edit-icon {
  margin-left: auto;
  color: #bab7b6;
}
.delete-order-button {
  background-color: transparent !important;
  border: none;
  color: #bab7b6;
  float: right;
  margin-top: -4px;
}
.delete-order-button:hover {
  color: red;
}
.delete-order-button:focus:active {
  box-shadow: none;
}

.order-edit-text-area {
  border: 1px solid #bab7b6;
  padding: 3px;
}
.order-edit-container {
  padding: 10px 30px;
  margin: 0px;
  overflow-y: scroll;
  width: unset;
}
.order-edit-divider {
  border-bottom: 1px solid #bab7b6;
  width: 100%;
}
.order-add-remove-button {
  display: flex;
  align-items: center;
}
.order-row {
  background-color: white;
  font-size: 14px;
}
.order-row td {
  padding: .5rem;
}

.order-row-alert {
  background-color: #ed9393;
}
.order-row-alert:hover {
  background-color: #d6d6d6;
  cursor: pointer;
}

.order-row:hover {
  background-color: #d6d6d6;
  cursor: pointer;
}

.icon-button:hover{
  cursor: pointer;
}

.disabled-icon-button {
  color: #bab7b6 !important;
}

.disabled-icon-button:hover {
  cursor: not-allowed;
}

.roles-table-header th {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 5;
  border: none;
  height: 50px;
}

.roles-table table {
  border: none !important;
  overflow-x: unset;
}

.roles-viewer {
  height: 80%;
  width: 100%;
  display: flex;
  justify-content: left;
  flex-direction: column;
  padding-right: 30px;
  padding-left: 30px;
}

.roles-viewer .header {
  display: flex;
  align-items: left;
  flex-direction: column;
  width: 250px;
  padding-bottom: 10px;
}

.roles-viewer .button {
  width: 300px;
  margin-top: 20px;
}

.roles-viewer .search-div {
  display: flex;
  width: 95%;
  justify-content: space-between;
}

.roles-viewer .table-div {
  overflow-y: auto;
  overflow-x: hidden;
  flex: 4;
  width: 100%;
}

.order-status-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 20px 0px;
}

.metric-table {
  width: auto;
  max-width: 620px;
  border: 1px #dee2e6 solid;
}
.metric-row td{
  background: white;
  border: none;
  font-weight: 500;
  font-size: 14px;
}
.metric-row th{
  background: white;
  border: none;
}
.raptor-admin-tabs {
  margin-left: 30px;
  margin-bottom: 20px;
  padding-top: 15px;
  text-align: right;
}
.queue-viewer-order-modal .modal-content {
  height: 100%;
  height: -moz-available; /* WebKit-based browsers will ignore this. */
  height: -webkit-fill-available; /* Mozilla-based browsers will ignore this. */
  height: fill-available;
  /*overflow-y: scroll;*/
}

.farm-org-user-typeahead {
    width: 40%;
    margin-bottom: 10px;
}

.delete-warning-modal .modal-content {
  background: #1c1c1c !important;
  color: white;
}
#upload-request-table td {
  word-wrap: break-word;
}

.contract-type-dropdown.dropdown.dropdown-toggle {
  width: 100%;
}

.contract-type-dropdown.dropdown.dropdown-menu {
  width: 100%;
}

.sales-onboarding-typeahead-with-button {
  width: calc(100% - 60px);
}

.client-email-list-typeahead .form-control {
  height: unset;
}

.sales-onboarding-button-icon {
  font-size: large;
  float: right;
}

.create-org-modal-component button {
  float: right;
}

.sales-onboarding-form-section {
  margin-bottom: 1rem;
}

.sales-onboarding-date-picker {
  width: 100%
}

.file-drop-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-style: dashed;
  border-color: lightgrey;
  border-width: 4px;
  padding: 4rem;
  border-radius: .3rem;
}

.order-loading-spinner {
  width: 7% !important;
  height: 7% !important;
  display: inline-block;
  fill: #f37c20 !important;
}


/*
Placeholder text color for hubspot deal input field  
*/

input[name="signed_proposal_link"]::placeholder {
  color: red;
}
/*
Placeholder text color for hubspot deal input field  
*/
input[name="hubspot_deal_url"]::placeholder {
  color: red;
}

.project-viewer-onboarding-modal {
  max-width: 98%;
}

.project-viewer-farm-orders-table-row {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.order-edit-confirm-cancel-body {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.default-org-group-input-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.edit-default-org-group-modal {
  max-width: 50%;
}