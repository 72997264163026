.table-divider {
  height: 20px;
  width: 100%;
}
.order-table-title {
  padding-top: 20px;
}
.order-table-wrapper {
  /* overflow-y: auto; */
}
.order-table-wrapper-limited {
  max-height: 600px;
  overflow-y: auto;
}
.order-table-header th {
  position: sticky;
  background: #efefef;
  top: 0;
  z-index: 5;
}
.hide-order-header thead {
  display: none;
}
.order-table thead {
  position: sticky;
  top: 0;
  z-index: 5;
}
.order-status-container {
  padding: 0 25px 25px 25px;
  display: flex;
  flex-direction: column;
  align-content: center;
}
.order-edit-row .rbt-input {
  height: auto !important;
}
#multistatus_table .row-expansion-style {
  padding: 0px;
}
#multistatus_table .table {
  margin-bottom: 0px;
}
#multistatus_table .table tr > td {
  cursor: pointer;
}
.multitable-header,
.multitable-header:hover {
  background: #48494a;
  color: #ffffff;
  font-size: 14px;
  position: relative;
  top: 0;
  z-index: 5;
}
.multitable-row {
  background: #b7babd;
  color: #48494a;
  font-size: 14px;
}
.multitable-row td {
  padding: 0.2rem;
}
.orders-search-bar {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.btn-orders-search-bar,
.btn-orders-search-bar:hover {
  background: #b7babd;
  color: #48494a;
  font-size: 14px;
  border-color: #b7babd !important;
  text-shadow: none !important;
}
.order-row td {
  padding: 0.3rem;
  font-size: 13px;
}
.order-priority-edit {
  display: flex;
  justify-content: center;
}
.btn-order-priority {
  width: 25px;
  height: 20px;
  display: flex;
  justify-content: center;
  padding: 0px;
  border: 1px solid #b7babd;
}
.order-edit-error {
  font-size: 12px;
  color: red;
}
.order-edit-field {
  display: flex;
  flex-direction: column;
}
.order-table-priority {
  width: 50px;
}
#flightBoundaryMap {
  width: 100%;
  height: 500px;
}

.data_quality_score {
  padding-top: 15px;
  padding-bottom: 15px;
  font-size: 20px;
}
.load-more-btn {
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  height: 40px;
  background-color: white;
  border-color: #48494a;
  color: #48494a;
}
.load-more-btn:hover {
  background-color: #48494a;
  border-color: #48494a;
  color: white;
}
