.nav-link {
  font-size: 14px;
  padding: 15px !important;
  border-bottom: 5px solid transparent !important;
}

.navbar-bottom {
  top: 0;
  border-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
}

.navbar-top_brand {
  padding: 2px 5px;
  height: 35px;
}

.user-menu-item {
  font-size: 14px;
}

.nav-user-icon {
  font-size: 30px;
  position: relative;
  top: 50%;
  transform: translateY(-50%)
}

.nav-help-icon {
  font-size: 30px;
  position: relative;
  top: 50%;
  transform: translateY(-50%)
}

.image-sets-item {
  font-size: 14px;
}

.org-menu-item {
  font-size: 14px;
}

.org-menu-item a {
  padding: 8px 22px !important;
  font-size: 14px;
}

.org-menu-edit {
  position: absolute;
  transition: opacity 0.125s ease-in-out;
  cursor: pointer;
  pointer-events: all;
  line-height: 1.42857143;
  color: #337ab7;
  right: 16px;
  top: auto;
  margin-top: 3px;
}

.org-menu-edit:hover {
  opacity: 0.6
}

.current-org-menu-item {
  font-size: 14px;
  background-color: #f6f8ff !important;
  border: none;
  cursor: default !important;
  color: #333 !important;
  font-weight: 700 !important;
}

.org-menu-item a:hover {
  color: #333 !important;
}

.add-org-menu-item {
  background-color: #f7f7f7;
  border-top: 1px dotted #e2e2e2;
}

.current-org-menu-item a {

}

.current-org-menu-item a:hover {
  background-color: transparent !important;
}

.admin-org-menu-item-name {
  margin-right: 16px;
}

/* Navigation */

.navbar-bottom_navbar .navbar-nav > li > a {
  padding-top: 15px;
  padding-bottom: 10px;
}

.navbar-light .navbar-nav .active > .nav-link, .navbar-light .navbar-nav .nav-link.active, .navbar-light .navbar-nav .nav-link.show > .nav-link {
  background-color: transparent !important;
  border-bottom: 5px solid #6c8ac5 !important;
}

.navbar-nav > li > .dropdown-menu {
  padding: 0;
}

.dropdown-menu > a {
  padding: 8px 22px;
  font-size: 14px;
}

.navbar-light .navbar-nav .show>.nav-link {
  background-color: #eee !important;
  color: #333 !important;
}

.tab-content {
  width: 100%;
}

