/* General CSS */

html, body {
  height: 100%;
  min-height: 100%;
  margin: 0;
  -webkit-font-smoothing: antialiased;
}

a {
  cursor: pointer;
}

.app {
  padding-top: 80px;
}

.nav-lite {
  margin-top: -30px;
}

#content {
  padding: 0px;
  height: 100%;
}

.modal-container {
  position: relative;
}

.modal-container .modal, .modal-container .modal-backdrop {
  position: absolute;
}

/* Just for checking the tile page */
#tilemap {
  height: 500px;
  width: 500px;
}

/* Used for top row that touches the navigation bar */
.nopadding {
  padding-top: 80px;
  /*padding-right: 0px;
  padding-left: 0px;*/
  padding-bottom: 0px;
  margin: 0 !important;
}

.toppadding {
  padding-top: 80px;
}

.noEdgePadding {
  padding-top: 0px;
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 15px;
  margin: 0 !important;
}

.row-top-padded {
  padding-top: 15px;
}

.row-top-padded-large {
  padding-top: 40px;
}

.row-padded-bottom {
  margin-bottom: 40px;
}

.fill {
  min-height: 100%;
  height: 100%;
}

.fill90 {
  min-height: 90%;
  height: 90%;
}

.pointer {
  cursor: pointer;
}

/* override some leaflet css to allow very large images
https://github.com/Leaflet/Leaflet/issues/2282
*/
.leaflet-container img.leaflet-image-layer {
  max-width: none !important;
}

/* pointer for reorganizing tile map rows */
.row-resize {
  cursor: row-resize;
}

.loading-screen {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  text-align: center;
}

.bottom-padding-small {
  margin-bottom: 10px !important;
}

.top-padding-large {
  padding-top: 100px;
}

.form-group.has-error {
  color: #dc3545;
  font-size: 12px;
  font-weight: 500;
}

.no-border-radius {
  border-radius: 0px;
}

.slogan {
  text-transform: uppercase;
  font-size: 15px;
  margin-bottom: 20px;
  color: #a5a5a5;
  font-weight: 500;
  line-height: 1.4;
}

.dotted-underline {
  border-bottom: 1px dotted;
  text-decoration: none !important;
}

.login-panel input {
  margin: 0px;
}

.login-panel input[type=hidden] {
  margin-top: -12px;
}

.login-panel p {
  margin: 0px 0px 12px 0px;
}

.login-panel p:last-child {
  margin: 0px;
}

.map-sidebar {
  padding-top: 20px;
  padding-bottom: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  position: relative;
  z-index: 999;
  background-color: white;
}

.no-side-padding {
  padding-left: 0;
  padding-right: 0;
}

.loading-modal-load-style {
  display: block;
  margin: auto;
  width: 64px;
  height: 80px;
}

.rm-loading-modal {
  margin-top: 100px;
  color: white;
  text-align: center;
}

.rm-loading-modal .modal-content {
  background: none;
  border: none;
  box-shadow: none;
}

.dropdown-menu {
  max-height: 50rem;
  overflow-y: scroll;
}

.DenyLayout{
  text-align: center;
}

.FilterBox .btn{
  width: 100%;
}

#login_user_form .btn-success{
    background-color: #f99f1b;
    border-color: #f57d20;
}

