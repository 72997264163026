

.org-users-container .header {
  padding: 20px;
}

.org-users-container .org-typeahead {
  width: 500px;
}

.empty-org-users {
  height: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.empty-org-users span {
  font-size: 18px;
  color: rgba(0,0,0,.5);
}


.org-view {
  padding: 30px;
}

.org-view .fa-trash:hover {
  cursor: pointer;
}

