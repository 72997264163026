.parent-div-org-permissions {
  display: flex;
  justify-content: center;
  width: 100%;
}

.child-div-org-permissions {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
  border-radius: 4px;
  width: 80%;
  margin-bottom: 24px;
  color: #212529;
  box-shadow: rgb(0 0 0 / 30%) 0px 0px 10px 1px;
}

.org-permissions-dumb-table {
  display: flex;
  width: 100%;
  min-height: 360px;
}

.permission-modal-title {
  display: flex;
  flex-direction: column;
}

.feature-permissions-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.feature-permissions-nav {
  margin: 0 1% 1% 1%;
}